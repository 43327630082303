.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: visible;

  .getStartedContainer {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-top: 100px;
    background: #3ABEF9;
    overflow: visible;

    .innerContainer {
      width: 1440px;
      color: white;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 768px) {
        width: 100%;
        flex-direction: column-reverse;

        img{
          width: 200px;
          height: auto;
        }
      }

      .text {
        width: 1250px;

        @media (max-width: 768px) {
            width: 100%;
        }

        .getInTouch {
          font-weight: 400;
          font-size: 24px;
          line-height: 28px;
          margin-bottom: 20px;

          @media (max-width: 768px) {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 0px;
            padding: 0 0 0 20px;
          }
        }

        h2 {
          font-weight: 600;
          font-size: 72px;
          line-height: 84px;
          margin-bottom: 40px;

          @media (max-width: 768px) {
            font-size: 18px;
            line-height: 20px;
            margin-bottom: 0px;
            padding: 0 0 0 20px;
          }
        }

        .description {
          font-weight: 400;
          font-size: 20px;
          line-height: 28px;
          margin-bottom: 20px;
          width: 600px;

          @media (max-width: 768px) {
            width: 100%;
            font-size: 14px;
            line-height: 18px;
            padding: 20px;
            margin-bottom: 0px;
          }
        }

        .controls {
          margin-top: 40px;

          @media (max-width: 768px) {
            margin-top: 0px;
            padding: 15px 20px;
          }

          a{
            box-shadow: none;
            font-size: 18px;
            font-weight: 600;
            line-height: 18px;
            text-align: center;

            @media (max-width: 768px) {
              font-size: 15px !important;
            }

            &.bookDemo {
              background: #fff;
              border-color: #fff;
              color: #170B01;
              margin-right: 20px;

              &:hover {
                background: #2788b3;
                border-color: #2788b3;
                color: #fff;
              }
            }

            &.contactUs {
              background: #3ABEF9;
              border: 1px solid #ffffff;

              &:hover {
                background: #2788b3;
                border-color: #2788b3;
              }
            }
          }
        }
      }

      .image {
        img {
          margin-top: -60px;
          margin-bottom: -6px;

          @media (max-width: 768px) {
            margin-top: 0px;
            margin-bottom: 0px;
          }
        }
      }

    }
  }
}

.headerInto {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  background: linear-gradient(to bottom, #fff, #effaff);
  overflow: visible;

  .headerInfoInner {
    margin: 0 auto;
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    overflow: visible;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
    }
  }

  .headerInfoLeft,
  .headerInfoRight {
    width: 50%;
    box-sizing: border-box;
  }

  .headerInfoLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 40px 0 0;
    text-align: left;
    width: 640px;

    @media (max-width: 768px) {
      width: auto;
      padding:0;
    }

    .primaryText {
      font-family: 'Arial', sans-serif;
      color: #170B01;
      font-size: 64px;
      line-height: 72px;
      font-weight: 500;

      @media (max-width: 768px) {
        font-size: 20px;
        line-height: 25px;
        margin-top: 20px;
      }
    }

    .mainTextDescription {
      margin-top: 20px;
      font-weight: 400;
      color: rgba(23, 11, 1, 0.72);
      font-family: 'Arial', sans-serif;
      font-size: 22px;
      line-height: 34px;

      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .learnMoreBtn {
      font-family: 'Arial', sans-serif;
      font-size: 18px;
      color: #FFFFFF;
      font-weight: 500;
      line-height: 18px;
      margin-top: 60px;
      background: #3ABEF9;
      border-radius: 30px;
      border: 3px solid #3ABEF9;
      text-transform: capitalize;
      cursor: pointer;
      transition: background 0.3s, color 0.3s, border-color 0.3s;
      padding: 16px 48px 16px 48px;

      @media (max-width: 768px) {
        font-size: 15px;
        padding: 10px 28px 10px 28px;
        margin-bottom: 20px;
      }

      &:hover {
        background: #2788b3;
        border-color: #2788b3;
      }
    }
  }

  .headerInfoRight {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
    //padding: 20px;

    img {
      @media (max-width: 768px) {
        width: 100%;
        height: auto;
      }
    }
  }
}
