
.testimonialsContainer {
  width: 100%;
  margin: 50px 0 -100px 0;
  padding: 100px;
  background: #f0f4f8;

  @media only screen and (max-width: 900px) {
    width: 100%;
    //margin: 0px 0 -100px 0;
    padding: 20px;
  }

  .testimonialsContainerInner {
    width: 70%;
    text-align: center;
    margin: 0 auto;

    @media only screen and (max-width: 900px) {
      width: 100%;
    }

    h3 {
      @media only screen and (max-width: 900px) {
        font-size: 1.5rem;
      }
    }

    p {
      @media only screen and (max-width: 900px) {
        font-size: 1rem;
        padding: 20px 20px 0 20px;
      }
    }

    h3, p {
      text-align: center;
      color: #000;
    }

    .screenContainer {

      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      padding-top: 50px;
      padding-bottom: 50px;

      @media only screen and (max-width: 900px) {
        padding: 0px;
      }

      .personContainer {
        width: 700px;
        height: 420px;
        background: #fff;
        margin: 10px;
        padding: 30px;
        position: relative;
        border-radius: 10px;

        @media only screen and (max-width: 900px) {
          height: 610px;
        }

        .imageContainer {
          width: 90px;
          height: 90px;
          border-radius: 50%;
          overflow: hidden;
          background-color: #eee;
          margin: 0 auto;
        }

        .name {
          font-size: 25px;
          font-weight: 600;
          line-height: 36px;
          text-align: center;
          color: #3ABEF9;
          margin-top: 15px;

          @media only screen and (max-width: 900px) {
            font-size: 20px;
            line-height: 23px;
          }
        }

        .position {
          font-size: 18px;
          font-weight: 400;
          line-height: 18px;
          text-align: center;
          margin-top: 15px;

          @media only screen and (max-width: 900px) {
            font-size: 15px;
            line-height: 18px;
          }
        }

        .review {
          font-size: 1.2rem;
          font-weight: 500;
          color: #2d2d2d;
          margin: 1rem 0;
          height: 150px;
          overflow-y: auto;
          padding: 0 10px 0 10px;

          @media only screen and (max-width: 900px) {
            font-size: 0.8rem;
            height: auto;
          }
        }
      }
    }
  }


}


/* width */
.review::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.review::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.review::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.review::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.carousel {
  :global .carousel-slider {
    display: flex;
    align-items: center;
  }
}

