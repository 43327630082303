.container{
    display: flex;
    width:100%;
    height: 704px;
    align-items: center;
    justify-content: center;
    /*margin-bottom: 4rem;*/
    background: transparent !important;
    z-index: 1;

    @media (max-width: 900px) {
        height: auto;
    }
}

.innerContainer{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    color: #170B01;
    align-items: flex-start;

    @media (max-width: 900px) {
        flex-direction: column-reverse !important;
        align-items: center;
    }

    .text{
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;

        @media (max-width: 900px) {
            max-width: 100%;
        }

        .primaryText{
            font-size:  60px;
            line-height: 72px;
            color: #170B01;
            font-weight: 500;
            margin-bottom: 30px;
            margin-top: 20px;

            @media (max-width: 900px) {
                font-size:  20px;
                line-height: 25px;
                margin-bottom: 10px;
            }
        }

        .contentText{
            font-size: 22px;
            line-height: 34px;
            font-weight: 400;

            @media (max-width: 900px) {
                font-size: 16px;
                line-height: 24px;
            }
        }

        .readMore{
            font-family: 'Arial', sans-serif;
            font-size: 18px;
            color: #FFFFFF;
            font-weight: 500;
            line-height: 18px;
            background: #3ABEF9;
            border-radius: 30px;
            border: 3px solid #3ABEF9;
            text-transform: capitalize;
            cursor: pointer;
            transition: background 0.3s, color 0.3s, border-color 0.3s;
            padding: 16px 48px 16px 48px;
            text-wrap: nowrap;
            margin-top: 50px;

            /*&:hover {*/
            /*    background: #2788b3;*/
            /*    border-color: #2788b3;*/
            /*}*/

            @media (max-width: 900px) {
                font-size: 15px;
                padding: 10px 28px 10px 28px;
                margin-top: 20px;
                margin-bottom: 50px;
            }
        }
    }

    .image{
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        display: flex;
        justify-content: space-between;

        @media (max-width: 900px) {
            -webkit-box-flex: 0;
            -ms-flex: initial;
            flex: initial;
            max-width: 100%;
        }

        img {
            @media (max-width: 900px) {
                width: 200px;
                height: auto;
            }
        }
    }
}









/*!* tablets *!*/
/*@media only screen and (max-width: 1260px) {*/
/*    .image img{*/
/*        width: 259px;*/
/*        height: 170px;*/
/*    }*/

/*    .contentText{*/
/*        width:100%;*/
/*    }*/

/*    .primaryText{*/
/*        font-size: 2rem;*/
/*    }*/

/*    .innerContainer{*/
/*        justify-content: space-evenly;*/
/*    }*/

/*    .container{*/
/*        height: auto;*/
/*    }*/

/*    .text, .image{*/
/*        flex: initial;*/
/*    }*/
/*}*/

/*!* mobiles *!*/
/*@media only screen and (max-width: 900px) {*/
/*    .image img{*/
/*        padding: 10px 0 10px 0;*/
/*        width: 300px;*/
/*        height: 210px;*/
/*    }*/

/*    .contentText{*/
/*        width:100%;*/
/*    }*/

/*    .primaryText{*/
/*        font-size: 2rem;*/
/*    }*/

/*    .innerContainer{*/
/*        justify-content: space-evenly;*/
/*        flex-direction: column-reverse !important;*/
/*    }*/

/*    .container{*/
/*        height: auto;*/
/*    }*/

/*    .text, .image{*/
/*        flex: initial;*/
/*        max-width:100%;*/
/*    }*/

/*    .text{*/
/*        padding:0 15px;*/
/*    }*/
/*}*/
